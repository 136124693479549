<template>
  <v-container>
    <h4>{{ $t("message.title-delivery") }}</h4>
    <v-row no-gutters align="center" justify="space-between">
      <v-col cols="12" md="8" xs="6">
        <v-row no-gutters align="center" justify="start">
          <v-col cols="7">
            <span>{{ $t("message.label-order-datetime") }}</span>
            <v-row>
              <v-col cols="5">
                <!-- <v-dialog
                  ref="dialog"
                  v-model="modalOrderFrom"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="orderDateFrom"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      clearable
                      outlined
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="orderDateFrom" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modalOrderFrom = false">
                      {{ $t("message.btn-cancel") }}
                    </v-btn>
                    <v-btn text color="primary" @click.prevent="submitDateFrom">
                      {{ $t("message.btn-ok") }}
                    </v-btn>
                  </v-date-picker>
                </v-dialog> -->

                <v-menu
                  v-model="modalOrderFrom"
                  :close-on-content-click="false"
                  :nudge-left="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  >
                  <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                      outlined
                      clearable
                      v-model="orderDateFrom"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      style="font-size: 14px; margin-top: -8px"
                      ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="orderDateFrom"
                      @input="modalOrderFrom = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col  cols="1" class="minus-amount" style="padding-right: 0px; padding-left: 0px;">
                <v-icon class="icon-tilde-class" size="18">mdi-tilde</v-icon>
              </v-col>
              <v-col cols="5" class="" >
                <!-- <v-dialog
                  ref="dialog"
                  v-model="modalOrderTo"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="orderDateTo"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      clearable
                      outlined
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="orderDateTo" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modalOrderTo = false">
                      {{ $t("message.btn-cancel") }}
                    </v-btn>
                    <v-btn text color="primary" @click="submitDateTo">
                      {{ $t("message.btn-ok") }}
                    </v-btn>
                  </v-date-picker>
                </v-dialog> -->

                <v-menu
                  v-model="modalOrderTo"
                  :close-on-content-click="false"
                  :nudge-left="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  >
                  <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                      outlined
                      clearable
                      v-model="orderDateTo"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      style="font-size: 14px; margin-top: -8px"
                      ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="orderDateTo"
                      @input="modalOrderTo = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="3">
            <span>{{ $t("message.delivery-user") }}</span>
            <!-- <v-dialog
              ref="dialog"
              v-model="modalDeliveryUser"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                
                  v-model="delivery_date"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  clearable
                  outlined
                  class="mt-2"
                ></v-text-field>
              </template>
              <v-date-picker v-model="delivery_date" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modalDeliveryUser = false">
                  {{ $t("message.btn-cancel") }}
                </v-btn>
                <v-btn text color="primary" @click.prevent="submitDeliveryUser">
                  {{ $t("message.btn-ok") }}
                </v-btn>
              </v-date-picker>
            </v-dialog> -->

            <v-menu
              v-model="modalDeliveryUser"
              :close-on-content-click="false"
              :nudge-left="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
              >
              <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                  class="mt-1"
                  outlined
                  clearable
                  v-model="delivery_date"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  style="font-size: 14px;"
                  ></v-text-field>
              </template>
              <v-date-picker
                  v-model="delivery_date"
                  @input="modalDeliveryUser = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="3">
        <v-row justify="end">
          <v-col style="padding-top: 0px">
            <v-btn
              style="float: right"
              rounded
              color="primary"
              @click.prevent="submitFilterDelivery"
            >
              <v-icon left>
                {{ iconSearch }}
              </v-icon>
              {{ $t("message.btn-search") }}
            </v-btn>
          </v-col>

          <v-col style="padding-top: 0px">
            <v-btn rounded color="primary" @click.prevent="printDelivery">
              <v-progress-circular
                v-if="isLoadingPrint"
                indeterminate
                color="white"
                size="11"
                left
              ></v-progress-circular>
              <v-icon left v-else>
                {{ iconDownload }}
              </v-icon>
              {{ $t("message.btn-download") }}
            </v-btn>
          </v-col>
        </v-row>


      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mdiMagnify } from "@mdi/js";
import { mdiDownload } from "@mdi/js";

export default {
  name: "FilterDelivery",
  data: () => ({
    iconSearch: mdiMagnify,
    iconDownload: mdiDownload,
    itemsList: [
      { id: 3, name: "配送中" },
      { id: 4, name: "受け取り済み" },
    ],
    requestDates: ["", ""],
    menu: false,
    modal: false,
    modal2: false,
    menu2: false,
    statusId: "",

    modalOrderFrom: false,
    modalOrderTo: false,
    orderDateFrom: "",
    orderDateTo: "",
    reqDateFrom: "",
    delivery_date: "",
    reqDateTo: "",
    modalReqFrom: false,
    modalDeliveryUser: false,
    modalReqTo: false,
    isLoadingPrint: false,
  }),
  created() {
    this.initFilter();
  },
  methods: {
    async printDelivery() {
      this.isLoadingPrint = true;
      await this.$store.dispatch("delivery_module/printDelivery", {
        // status: this.statusId == null || undefined ? "" : this.statusId,
        // reqFrom: this.reqDateFrom == null || undefined ? "" : this.reqDateFrom,
        // reqTo: this.reqDateTo == null || undefined ? "" : this.reqDateTo,
        delivery_date:
          this.delivery_date == null || undefined ? "" : this.delivery_date,
        dateFrom:
          this.orderDateFrom == null || undefined ? "" : this.orderDateFrom,
        dateTo: this.orderDateTo == null || undefined ? "" : this.orderDateTo,
      });
      this.isLoadingPrint = false;
    },
    submitDateFrom() {
      this.modalOrderFrom = false;
      this.$refs.dialog.save(this.orderDateFrom);
    },
    submitDateTo() {
      this.modalOrderTo = false;
      this.$refs.dialog.save(this.orderDateTo);
    },
    submitDeliveryUser() {
      // this.modalReqFrom = false;
      this.modalDeliveryUser = false;
      this.$refs.dialog.save(this.reqDateFrom);
    },
    submitReqDateTo() {
      this.modalReqTo = false;
      this.$refs.dialog.save(this.reqDateTo);
    },
    submitFilterDelivery() {
      if (!this.statusId) this.statusId = "";
      this.$store.dispatch("delivery_module/fetchDelivery", {
        status: this.statusId,
        orderDatesFrom: this.orderDateFrom === null ? "" : this.orderDateFrom,
        orderDatesTo: this.orderDateTo === null ? "" : this.orderDateTo,
        orderDatesFrom: this.orderDateFrom === null ? "" : this.orderDateFrom,
        delivery_date:
          this.delivery_date == null || undefined ? "" : this.delivery_date,
        // reqDateFrom: this.reqDateFrom === null ? "" : this.reqDateFrom,
        // reqDateTo: this.reqDateTo === null ? "" : this.reqDateTo,
        page: 1,
      });
    },
    initFilter() {
      this.orderDateFrom = this.$store.state.delivery_module.dateFrom;
      this.orderDateTo = this.$store.state.delivery_module.dateTo;
      this.delivery_date = this.$store.state.delivery_module.delivery_date;
    },
  },

  computed: {
    requestDateRangeText() {
      // return this.requestDates.join(" ~ ");
      if (this.requestDates[0] !== "" && this.requestDates[0] !== "") {
        return this.requestDates.join(" ~ ");
      }
    },
  },
};
</script>

<style>
.btn-search {
  border-radius: 20px;
}
.v-input .v-input__control .v-text-field__details {
  /* centering text field filter order */
  /* display: none; */
}
/* .v-input__control .v-input__slot .v-text-field__slot input {
  width: 70px;
} */
.v-select.v-text-field--outlined:not(.v-text-field--single-line)
  .v-select__selections {
  /* padding: 0px !important; */
}
.v-text-field--enclosed .v-input__prepend-inner {
  margin-top: 0px !important;
  align-self: center !important;
}
.v-text-field .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}
.v-text-field--enclosed .v-input__append-inner,
.v-text-field--enclosed .v-input__append-outer {
  margin-top: 0px !important;
  align-self: center !important;
}
.minus-amount {
  max-width: fit-content !important;
  margin-top: -8px;
}
</style>
