<template>
  <v-container>
    <FilterDelivery />

    <!-- data table -->
    <v-pagination
      v-if="!isLength"
      v-model="page"
      :length="lengthPage"
      :total-visible="7"
      @input="changePage"
    ></v-pagination>

    <v-data-table
      :headers="headers"
      :items="deliveryList"
      :hide-default-footer="true"
      disable-sort
      justify="center"
    >
      <template v-slot:[`item.delivery_datetime`]="{ item }">
        <span
          class="red--text"
          v-if="formatDate(item.delivery_datetime) == formatDate(today)"
        >
          {{ item.delivery_datetime }}
        </span>
        <span v-else>
          {{ item.delivery_datetime }}
        </span>
      </template>

      <template v-slot:[`item.notes`]="{ item }">
        <span style="text-align: justify;" v-if="item.order_shipping_type == 1">
          置き配を希望する
        </span>
        <span style="text-align:justify; white-space: pre-line">
          {{ item.notes }}
        </span>
      </template>

      <!-- action table -->
      <template v-slot:[`item.action`]="{ item }">
        <v-icon
          color="indigo"
          small
          class="mr-2"
          @click.prevent="showDetail(item.id)"
        >
          mdi-eye
        </v-icon>
        <!-- <v-icon color="red" small>
          mdi-cancel
        </v-icon> -->
      </template>

      <!-- ADD CLICKABLE REDIRECT BY ORDER NUMBER -->
      <template v-slot:[`item.order_no`]="{ item }">
        <a href="" @click.prevent="showDetail(item.id)">{{ item.order_no }}</a>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import FilterDelivery from "../components/FilterDelivery.vue";

export default {
  name: "DeliveryManagement",
  components: { FilterDelivery },
  data: () => ({
    option: {},
    page: 1,
    today: new Date(),
  }),
  watch: {
    deliveryList: {
      handler() {
        this.page = parseInt(this.$store.state.delivery_module.page);
      },
      deep: true,
    },
  },

  methods: {
    formatDate(date) {
      return new Date(new Date(date) - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);
    },
    fetchDeliveryList() {
      // const { page } = this.option;
      let pageInit = parseInt(this.$store.state.delivery_module.page);
      this.page = parseInt(this.$store.state.delivery_module.page);
      this.$store.dispatch("delivery_module/fetchDelivery", { page: pageInit });
    },
    showDetail(id) {
      this.$router.push("orderDetail/" + id);
    },
    changePage(event) {
      return this.$store.dispatch("delivery_module/fetchDelivery", {
        page: event,
      });
    },
  },

  computed: {
    isLength() {
      return this.$store.state.delivery_module.is_length;
    },
    lengthPage() {
      return this.$store.state.delivery_module.length_page;
    },
    deliveryList() {
      return this.$store.state.delivery_module.deliveryListResult;
    },
    deliveryHeader() {
      return this.$store.state.delivery_module.deliveryHeaderResult;
    },
    headers() {
      return [
        {
          text: this.$t("message.label-order-id"),
          align: "start",
          value: "order_no",
        },
        {
          text: this.$t("message.delivery-user"),
          value: "delivery_datetime",
          width: "13%",
        },
        {
          text: this.$t("message.label-status"),
          value: "status",
          width: "10%",
        },
        {
          text: this.$t("message.label-shop"),
          value: "store_name",
        },
        {
          text: this.$t("message.label-destination"),
          value: "delivery_destination",
        },

        {
          text: this.$t("message.quarantine-type"),
          value: "order_type_label",
          align: "center",
        },
        {
          text: "【備考】",
          value: "notes",
          align: "left",
        },
        {
          text: this.$t("message.label-order-datetime"),
          value: "created_at",
        },
        {
          text: this.$t("message.label-action"),
          value: "action",
          align: "center",
          width: "10%",
        },
      ];
    },
  },

  created() {
    this.fetchDeliveryList();
  },
};
</script>

<style>
v-data-table >>> div > table > tr {
  border-spacing: 5px;
}
thead {
  background: #3949ab !important;
  text-transform: capitalize;
}
thead tr th {
  color: white !important;
  font-size: 15px !important;
}
tbody tr:hover:not(.v-table__expanded__content) {
  /* background: #c5cae9 !important; */
}
.icon-1 .icon-2 {
  cursor: pointer;
}
.v-application--is-ltr .v-data-footer__select {
  margin-left: 0px !important;
}
</style>
