var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('FilterDelivery'),(!_vm.isLength)?_c('v-pagination',{attrs:{"length":_vm.lengthPage,"total-visible":7},on:{"input":_vm.changePage},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e(),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.deliveryList,"hide-default-footer":true,"disable-sort":"","justify":"center"},scopedSlots:_vm._u([{key:"item.delivery_datetime",fn:function(ref){
var item = ref.item;
return [(_vm.formatDate(item.delivery_datetime) == _vm.formatDate(_vm.today))?_c('span',{staticClass:"red--text"},[_vm._v(" "+_vm._s(item.delivery_datetime)+" ")]):_c('span',[_vm._v(" "+_vm._s(item.delivery_datetime)+" ")])]}},{key:"item.notes",fn:function(ref){
var item = ref.item;
return [(item.order_shipping_type == 1)?_c('span',{staticStyle:{"text-align":"justify"}},[_vm._v(" 置き配を希望する ")]):_vm._e(),_c('span',{staticStyle:{"text-align":"justify","white-space":"pre-line"}},[_vm._v(" "+_vm._s(item.notes)+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":"indigo","small":""},on:{"click":function($event){$event.preventDefault();return _vm.showDetail(item.id)}}},[_vm._v(" mdi-eye ")])]}},{key:"item.order_no",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.showDetail(item.id)}}},[_vm._v(_vm._s(item.order_no))])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }